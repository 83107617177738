import React, { memo, MouseEvent, useContext, useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { GlobalContext, IGlobalContext } from 'src/context/GlobalContext'
import useLocalStorage from 'src/hooks/window/useLocalStorage'
import useScroll from 'src/hooks/window/useScroll'
import { getParameterByName } from 'src/shared/helpers'

import '../UI/rating-app/rating-app.scss'
import './layout.scss'

import { alphaCode } from 'src/utils/alpha-code-utms'
import OrangeIcon from '../UI/MarkdownIcon/OrangeDsIcon'

const INVALID_PAGES = [
  '/pra-voce/financiamento-imobiliario/residencial/',
  '/empresas/conta-digital/pessoa-juridica/',
  '/interday-app/',
]

type getAppBannerProps = {
  i18n: { language: string };
  scrollTopHomeValue: number;
}

function GetAppBanner ({ i18n, scrollTopHomeValue }: getAppBannerProps) {
  const [ transitionClass, setTransitionClass ] = useState('close-banner')
  const [ validPage, setValidPage ] = useState(true)
  const [ banner, setBanner ] = useLocalStorage('@bancointer-banner', true)
  const scrollPositionY = useScroll(0)
  const withStoreButton = [ '/convite-abrir-conta/' ]
  const [ utm_source, setUtmSource ] = useState<string>('9io9bkj')
  const [ utm_campaign, setUtmCampaign ] = useState<string>('site_contadigital_aquisicao')
  const { scrollHomeMobileValue }: IGlobalContext = useContext(GlobalContext)

  useEffect(() => {
    const footerHeight = document.body.scrollHeight - 2500
    const smartBanner = scrollPositionY >= 300 && scrollPositionY < footerHeight
    const containsStoreButton = withStoreButton.includes(window.location.pathname)
    const isHome = window.location.pathname === '/'

    if (scrollTopHomeValue) {
      if (scrollTopHomeValue >= 500) {
        setTransitionClass('open-banner')
      } else {
        setTransitionClass('close-banner')
      }
    } else {
      if (isHome && scrollHomeMobileValue >= 832 && scrollHomeMobileValue < 6656) {
        setTransitionClass('open-banner')
      } else if (smartBanner && !containsStoreButton) {
        setTransitionClass('open-banner')
      } else if ((scrollPositionY <= window.innerHeight || scrollPositionY > footerHeight) && !containsStoreButton) {
        setTransitionClass('close-banner')
      }
    }
  }, [ scrollPositionY, scrollTopHomeValue, scrollHomeMobileValue ])

  useEffect(() => {
    function checkValidPage () {
      if (INVALID_PAGES.includes(window.location.pathname)) {
        setValidPage(false)
      }
    }
    checkValidPage()

    const source = getParameterByName('utm_source', '') || '9io9bkj'
    const campaing = getParameterByName('utm_campaign', '') || 'site_contadigital_aquisicao'

    setUtmSource(source as string)
    setUtmCampaign(campaing as string)
  }, [])

  function closeUseLocal (evt: MouseEvent<HTMLButtonElement>) {
    evt.preventDefault()
    setBanner(false)
  }

  return (
    <>
      {
        (banner && validPage && i18n.language === 'pt') &&
          (
            <a href='https://inter-co.onelink.me/Qyu7/9285w5eu'>
              <div className={`btn-get-app d-flex d-lg-none justify-content-center ${transitionClass}`}>
                <button
                  className='close'
                  onClick={(evt: MouseEvent<HTMLButtonElement>) => closeUseLocal(evt)}
                >
                  <OrangeIcon size='SM' icon='exit' color='white' />
                </button>
                <div className='col-6 pl-4 text-banner mt-3'>
                  <span className='fs-16 font-sora fw-600 mb-0'>Inter&Co</span>
                  <p className='text-grayscale--500 fs-12 lh-14 fw-500 mb-0'>Baixe o Super App e <span className='d-block'>simplifique sua vida.</span></p>
                </div>
                <a
                  style={{ width: '130px', minWidth: 130 }}
                  className='col-4 text-white text-none fs-12 btn btn-orange--extra btn--lg rounded-2 mt-4'
                  href='https://inter-co.onelink.me/Qyu7/9285w5eu'
                >
                  Baixar o App
                </a>
              </div>
            </a>
          )
      }
    </>
  )
}

export default translate('Components')(memo(GetAppBanner))
